import { Component } from 'react';
import PropTypes from 'prop-types';
import {getBackgroundImageValue} from "../../stores/StyleStore";


export default class ImageComponent extends Component {
    static propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        isBackground: PropTypes.bool,
        className: PropTypes.string,
        onClick: PropTypes.func,
        onLoad: PropTypes.func,
        onError: PropTypes.func,
    };


    static defaultProps = {
        src: "",
        alt: "",
        isBackground: false,
        className: "",
        onClick: () => {
        },
        onLoad: () => {
        },
    };

    constructor(props) {
        super(props);
        this.state = {
            validImage: true,
        }
    }

    render() {
        if (!this.props.onError) {
            this.onError = () => {
                this.setState({validImage: false})
            }
        }
        else {
            this.onError = this.props.onError
        }
        const {alt, isBackground, className, ...restProps} = this.props;

        let image;
        if (isBackground) {
            image =
                <div onClick={this.props.onClick} {...restProps} style={getBackgroundImageValue(this.props.src)}
                     className={className}
                     role="img" title={alt}>{this.props.children}</div>
        } else if (this.state.validImage) {
            image = <img onLoad={this.props.onLoad} onClick={this.props.onClick} {...restProps} src={this.props.src}
                         className={className} aria-label={alt} alt={alt} onError={this.onError} />
        }

        return image;
    }
}
